/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import {Comment, Item, materialsTypologyCode, noTypology, Typology} from "shared/model/model";
import {Box, Typography} from "@mui/material";
import {getStandardImageUrl, getThumbnailImageUrl, getTypology, logInfo} from "shared/utils";
import {css} from "@emotion/react";
import {colors} from "shared/styles/vars";

import {useReferentialsState} from "state/ReferentialsState";
import {DownloadableImg} from "../../../components/DownloadableImg";
import downloadjs from "downloadjs";


export const ItemPropertiesPanel = (props: {
    item: Item
    fullPage: boolean
}) => {

    const [comments, setComments] = useState([] as Array<Comment>);
    const [brand, setBrand] = useState('');
    const [price, setPrice] = useState('');
    const [name, setName] = useState('');
    const [currency, setCurrency] = useState('');
    const [packingUnit, setPackingUnit] = useState('');
    const [imageId, setImageId] = useState('');
    const [supertypology, setSupertypology] = useState('');
    const [typology, setTypology] = useState('');
    const [subtypology, setSubtypology] = useState('');
    const [length, setLength] = useState('');
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [diameter, setDiameter] = useState('');
    const [notes, setNotes] = useState<string>('');
    const [comment, setComment] = useState('');

    const typologies = useReferentialsState(s => s.typologies)

    const handleDownloadImage = async () => {
        let downloadImageUrl = getStandardImageUrl(imageId)
        logInfo(`Downloading image ${downloadImageUrl}`)
        downloadjs(downloadImageUrl, `${props.item.name}.png`, 'image/png');
    }

    useEffect(() => {
        let item = props.item
        setName(item.name)
        setImageId(item.imageId)
        setBrand(item.brand ?? '')
        setPrice(item.price ? item.price.toLocaleString() : '')
        setCurrency(item.currency)
        setPackingUnit(item.packingUnit)
        setSupertypology(item.supertypology ? item.supertypology : noTypology.code)
        setTypology(item.typology ? item.typology : noTypology.code)
        setSubtypology(item.subtypology ? item.subtypology : noTypology.code)
        setLength(item.dimensions.length?.toLocaleString() ?? '')
        setWidth(item.dimensions.width?.toLocaleString() ?? '')
        setHeight(item.dimensions.height?.toLocaleString() ?? '')
        setDiameter(item.dimensions.diameter?.toLocaleString() ?? '')
        setNotes(item.notes)
    }, [props.item]);


    const currentSupertypology = getTypology(typologies, supertypology)
    const currentTypology = getTypology(typologies, typology)
    const currentSubtypology = getTypology(typologies, subtypology)
    const isMaterial = materialsTypologyCode == currentSupertypology.code

    const imageUrl = imageId && getThumbnailImageUrl(imageId)

    const itemNameStyle = props.fullPage ? "h4" : "h5"
    const itemBrandStyle = props.fullPage ? "h5" : "h6"
    const priceStyle = props.fullPage ? "h5" : "body1"
    const priceColor = props.fullPage ? "black" : "grey"

    return (
        <div>
            <div css={css`color:grey;display: none`}>
                                            <span>
                                                {currentSupertypology.code != noTypology.code &&
                                                currentSupertypology.label
                                                }
                                            </span>
                <span>
                                                {currentTypology.code != noTypology.code &&
                                                " > " + currentTypology.label
                                                }
                                            </span>
                <span>
                                                {currentSubtypology.code != noTypology.code &&
                                                " > " + currentSubtypology.label
                                                }
                                            </span>

            </div>

            <div css={css`margin-top:8px`}>
                <Typography variant={itemNameStyle} css={css`text-align:left`}>{name}</Typography>
                <Typography variant={itemBrandStyle} css={css`text-align:left;color:${colors.mediumgrey}`}>{brand}</Typography>
                <div css={css`margin-top:8px`}>
                    <Box>
                        <Typography variant={priceStyle} color={priceColor}>
                            {price} {currency}
                        </Typography>
                        {isMaterial && packingUnit &&
                        <Typography variant="body1">
                            ({packingUnit})
                        </Typography>
                        }
                    </Box>
                </div>
            </div>

            <div css={css`display: flex;
                                  align-items: center;
                                  flex-wrap: wrap;
                                  justify-content: center;
                                  `}>

                {imageUrl &&
                    <DownloadableImg imgSrc={imageUrl} css={css`
                        object-fit: contain;
                        max-height: 300px;
                        max-width: 300px;
                        object-position: center;
                        `} onDownload={handleDownloadImage}
                    />
                }

            </div>


            {(length || width || height || diameter) &&
            <div
                css={css`width:100%;font-size:0.8em;margin-top: 20px;color:${colors.darkgrey}`}>

                <div>
                    {length &&
                    <div>
                        Longueur : {length}
                    </div>
                    }
                    {width &&
                    <div>
                        Largeur : {width}
                    </div>
                    }
                    {height &&
                    <div>
                        Hauteur : {height}
                    </div>
                    }
                    {diameter &&
                    <div>
                        Diamètres : {diameter} cm
                    </div>
                    }
                </div>

            </div>
            }

            {notes &&
            <div
                css={css`background-color: ${colors.clearGrey};font-style:italic;width:100%;font-size:0.8em;padding:10px;margin-top: 20px`}>
                {notes}
            </div>
            }

        </div>

    )
};