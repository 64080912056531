import {CsrfTokenService} from './shared/services/CsrfTokenService';
import {HttpService} from './services/HttpService';
import {NotificationService} from './services/NotificationService';
import {LoginService} from "./shared/services/LoginService";
import {AccountService} from "./shared/services/AccountService";
import {RegistrationService} from "./shared/services/RegistrationService";
import {ItemService} from "./shared/services/ItemService";
import {ProjectService} from "./shared/services/ProjectService";
import {ReferentialService} from "./shared/services/ReferentialService";
import {BoardService} from "./services/BoardService";
import {ListingService} from "./shared/services/ListingService";
import {SessionAwareHttpService} from "./services/SessionAwareHttpService";

class ApplicationContext {

    private instances = new Map<String, any>();

    constructor() {
        this.register("csrfTokenService", () => new CsrfTokenService());
        this.register("sessionAwareHttpService", () => new SessionAwareHttpService());
        this.register("httpService", () => new HttpService());
        this.register("notificationService", () => new NotificationService());
        this.register("loginService", () => new LoginService());
        this.register("userService", () => new AccountService());
        this.register("registrationService", () => new RegistrationService());
        this.register("itemService", () => new ItemService());
        this.register("projectService", () => new ProjectService());
        this.register("listingService", () => new ListingService());
        this.register("referentialService", () => new ReferentialService());
        this.register("boardService", () => new BoardService());
    }

    public csrfTokenService: () => CsrfTokenService = () => this.get("csrfTokenService");
    public sessionAwareHttpService: () => SessionAwareHttpService = () => this.get("sessionAwareHttpService");
    public httpService: () => HttpService = () => this.get("httpService");
    public notificationService: () => NotificationService = () => this.get("notificationService");
    public loginService: () => LoginService = () => this.get("loginService");
    public userService: () => AccountService = () => this.get("userService");
    public registrationService: () => RegistrationService = () => this.get("registrationService");
    public itemService: () => ItemService = () => this.get("itemService");
    public projectService: () => ProjectService = () => this.get("projectService");
    public listingService: () => ListingService = () => this.get("listingService");
    public referentialService: () => ReferentialService = () => this.get("referentialService");
    public boardService: () => BoardService = () => this.get("boardService");

    private register<T>(name: String, creator: () => T) {
        this.instances.set(name, creator());
    }

    private get<T>(name: String): T {
        return this.instances.get(name);
    }

}

export const appContext = new ApplicationContext();
