import {appContext} from 'ApplicationContext';
import {Instant} from 'shared/model/time';
import config from 'config';
import {logError} from "../shared/utils";

export type RequestType = 'get' | 'post' | 'put' | 'delete';

export const displayErrorMessage = 'DisplayError';

export interface HttpResponse {
    status: number;
    body?: any;
}

export interface RequestError {
    id?: string;
    status: number;
    error: string;
    message: string;
    instant: Instant;
    stackTrace?: any;
}

export interface RequestOptions {
    timeout: number
}

export class HttpService {
    public credentials: RequestCredentials = config.credentials.sharing as RequestCredentials;

    public getBody = <R>(url: string, getParams?: any): Promise<R> =>
        this.get(url, getParams).then(r => r.body);

    public get = (url: string, getParams?: any) =>
        this.fetchAndDeserialize('get', url, getParams);

    public postBody = <R>(url: string, bodyObject?: any): Promise<R> =>
        this.post(url, bodyObject).then(r => r.body);

    public putBody = <R>(url: string, bodyObject?: any): Promise<R> =>
        this.put(url, bodyObject).then(r => r.body);

    public post = (url: string, bodyObject?: any) =>
        this.fetchAndDeserialize('post', url, null, JSON.stringify(bodyObject));

    public postMultipart = <R>(url: string, formData?: any): Promise<R> =>
        this.fetchAndDeserialize('post', url, null, undefined, formData).then(r => r.body);

    public put = (url: string, bodyObject?: any) =>
        this.fetchAndDeserialize('put', url, null, JSON.stringify(bodyObject));

    public delete = (url: string, bodyObject?: any) =>
        this.fetch('delete', url, null, JSON.stringify(bodyObject));

    private fetchAndDeserialize(
        requestType: RequestType,
        url: string,
        getParams?: any,
        bodyAsString?: string,
        bodyFormData?: FormData
    ): Promise<HttpResponse> {
        return this.fetch(requestType, url, getParams, bodyAsString, bodyFormData).then(
            (response: Response) => {
                if (response.ok) {
                    return response.text().then(t => ({
                        status: response.status,
                        body: t !== '' ? JSON.parse(t) : undefined
                    }));
                } else {
                    return response.text().then(t => {
                        try {
                            const error: RequestError = JSON.parse(t);
                            if (error.error === displayErrorMessage) {
                                return Promise.reject(displayErrorMessage);
                            }
                            return Promise.reject(error);
                        } catch (e) {
                            logError("no error info");
                            return Promise.reject(response.status)
                        }
                    });
                }
            }
        );
    }

    public fetchBinary = async (
        requestType: RequestType,
        url: string,
        mimeType: string,
        getParams?: any,
        bodyAsString?: string,
        bodyFormData?: FormData,
        options?: RequestOptions
    ): Promise<Blob> => {
        const {params, timeoutId} = this.prepareParams(requestType, mimeType, bodyAsString, bodyFormData, options)
        if (bodyAsString) {
            params.body = bodyAsString;
        } else if (bodyFormData) {
            params.body = bodyFormData;
        }
        let finalUrl = this.createFinalUrl(url, getParams);
        let response = await fetch(finalUrl, params)
        if (!response.ok) {
            throw new Error(`Error ${response.status}`);
        } else {
            return response.blob();
        }
    }

    public fetch = async (
        requestType: RequestType,
        url: string,
        getParams?: any,
        bodyAsString?: string,
        bodyFormData?: FormData,
        options: RequestOptions = {timeout: 10000}
    ): Promise<Response> => {
        const {params, timeoutId} = this.prepareParams(requestType, 'application/json', bodyAsString, bodyFormData, options);
        let finalUrl = this.createFinalUrl(url, getParams)
        let response = await fetch(finalUrl, params)
        clearTimeout(timeoutId)
        return response
    }

    private prepareParams(requestType: "get" | "post" | "put" | "delete", mimeType: string, bodyAsString: string | undefined, bodyFormData: FormData | undefined, options: RequestOptions | undefined) {
        const timeout = options ? options.timeout : 10000;
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), timeout);
        const params: RequestInit = {
            method: requestType,
            headers: {
                Accept: mimeType,
                ...(bodyAsString &&
                    {
                        'Content-Type': 'application/json',
                    }
                ),
                ...((appContext.csrfTokenService().token) &&
                    {
                        [appContext.csrfTokenService().header]:
                        appContext.csrfTokenService().token
                    }
                )
            },
            credentials: this.credentials
        };
        if (bodyAsString) {
            params.body = bodyAsString;
        } else if (bodyFormData) {
            params.body = bodyFormData
        }
        return {params, timeoutId};
    }

    private createFinalUrl(url: string, getParams: any) {
        let finalUrl = url;
        if (getParams) {
            let paramKeysValues = "";
            let entries = Object.entries(getParams);
            entries.forEach(([key, value], index) => {
                // @ts-ignore
                paramKeysValues += (key + "=" + encodeURIComponent(value.toString()));
                if (index < entries.length - 1) {
                    paramKeysValues += "&";
                }
            });
            finalUrl += '?' + paramKeysValues;
        }
        return finalUrl;
    }

}
